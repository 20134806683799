/**
 * Created by Webdreamers
 */

jQuery('.appear').appear();
jQuery('.animate').appear({force_process: true});

jQuery(".animate").on("appear", function(data) {
    jQuery(this).addClass("animate_start");
});




$(document).ready(function() {

    $(window).scroll(function() {
        if ($(this).scrollTop() > 200) {
            $('.go-top').fadeIn(200);
        } else {
            $('.go-top').fadeOut(200);
        }
    });


    $('.go-top').click(function(event) {
        event.preventDefault();

        $('html, body').animate({scrollTop: 0}, 300);
    })
});